.about-section {
  margin-top: 100px;
  border-top: 1px solid #d8d8d8;
  position: relative;
}

.about-section::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;

  top: 0;
  left: 50%;

  background-color: #d8d8d8;
}

.about-section__content {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.about-section__content__left,
.about-section__content__right {
  padding: 10px;
  width: 45%;
}

.about-section__content__left p,
.about-section__content__right p {
  margin-top: 30px;
}
.photographer-img {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
}

.photographer-container {
  position: relative;
  margin-top: 50px;
}

.photographer-flower {
  position: absolute;
  top: -200px;
  left: -170px;
  z-index: -9999;
}

@media (max-width: 900px) {
  .about-section {
    margin-top: 30vh;
    border-top: 1px solid #d8d8d8;
    position: relative;
  }

  .about-section::before {
    display: none;
  }

  .about-section__content {
    flex-direction: column;
  }

  .about-section__content__left,
  .about-section__content__right {
    width: 100%;
  }

  .photographer-flower {
    display: none;
  }

  .about__logo {
    height: 100px;
  }
}
