:root {
  --brown: #220013;
  --brown-light: #979797;
  --brown-red: #6a080d;
  --brown-dark: #322518;
  --gray: #f3f0e1;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100vh;
  width: 100vw;
  font-size: 16px;
  overflow-x: hidden;
  #font-family: "Raleway", sans-serif;
  font-family: 'Montserrat', sans-serif;

  scroll-behavior: smooth;
}
input,
button {
  font-size: 1rem;
  border: none;
  outline: none;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}
.container {
  max-width: 1000px;
  width: 100%;

  margin: 0 auto;
}

.brown {
  color: var(--brown);
}
.brown-light {
  color: var(--brown-light);
}
.brown-red {
  color: var(--brown-red);
}
.brown-dark {
  color: var(--brown-dark);
}
