.contact-us {
  padding: 50px 0;
  background-color: var(--brown);
}

.contact-us p {
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  max-width: 700px;
  width: 100%;
  margin: 0 auto;

  gap: 10px;
}
.inputs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 0 auto;

  gap: 10px;
}
.checkbox-container {
  display: flex;
  justify-content: start;
  align-items: start;
}
/* reseta estilos input number */

.inputs-container input[type="number"]::-webkit-inner-spin-button,
.inputs-container input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputs-container input[type="number"] {
  -moz-appearance: textfield;
}

.inputs-container input,
.inputs-container select {
  width: 100%;

  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #97906d;
  border-radius: 5px !important;

  font-size: 0.9rem;
}
/* Define o border radius do select */

.inputs-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  border-radius: 5px !important;
}

.input-group {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.input-group input,
.input-group select {
  flex: 1;
}

input[type="checkbox"] {
  width: 25px;
  height: 25px;
}

.contatos {
  margin-top: 25px;
}

@media (max-width: 900px) {
  .contatos {
    margin-top: 25px;
    flex-direction: column!important;
  }
}