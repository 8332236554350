.who-we-are {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.who-we-are__text {
  text-align: center;
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
}

.img-background {
  border-radius: 50%;
  background-color: #f3f0e1;
  position: relative;
  display: inline-block;
}

.balao {
  position: absolute;
  top: -120px; /* Ajuste a posição vertical conforme necessário */
  left: 50%; /* Centralize horizontalmente */
  transform: translateX(-50%); /* Centralize horizontalmente */
  background-color: var(--gray);
  color: var(--brown);
  padding: 10px;
  border-radius: 15px;
  display: none;
  width: 60vh;
}

.img-background:hover .balao {
  display: block;
}

.foto-perfil {
  width: 200px;
  height: 200px;
}

@media (max-width: 900px) {
  .balao {
    position: absolute;
    top: -110px; /* Ajuste a posição vertical conforme necessário */
    left: 50%; /* Centralize horizontalmente */
    transform: translateX(-50%); /* Centralize horizontalmente */
    background-color: var(--gray);
    color: var(--brown);
    padding: 10px;
    border-radius: 15px;
    display: none;
    width: 40vh;
  }
}