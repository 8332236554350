.area-of-activity__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}

.area-of-activity__content p {
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.area-of-activity__content__cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.area-of-activity__content__cards .cards {
  /*background-color: var(--gray);*/

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 250px;
  padding: 20px;
  text-align: center;
  border-radius: 10px;

  border: 4px solid #EBEBEB;
}

.area-of-activity {
  position: relative;
  border-top: 1px solid #d8d8d8;
  margin-top: 50px;
}

.area-of-activity-flower-right,
.area-of-activity-flower-left {
  position: absolute;
  z-index: -9999;
}

.area-of-activity-flower-right {
  top: 0;
  right: -10px;
}

.area-of-activity__logo {
  margin-bottom: -60px;
}

@media (max-width: 900px) {
  .area-of-activity-flower-right,
  .area-of-activity-flower-left {
    display: none;
  }

  .area-of-activity__content__cards {
    flex-direction: column;
    align-items: center;
  }

  .area-of-activity__logo {
    height: 100px;
  }
}
