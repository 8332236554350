header {
  width: 100%;
  padding: 20px 0;
  margin-top: 30px;
}

header .logotipo {
  width: 230px;
}

.hero-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
}

.hero-container-content {
  width: 60%;
  height: 450px;
  display: flex;
  justify-content: space-between;
}

.imagem-fundo {
  width: 100%;
  height: 89vh;
  display: flex;
  justify-content: space-between;
  background-image: url("../../assets/capa_site.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-header__content {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.scroll-mouse {
  width: 40px;
}

.hero-header h1 {
  font-size: 2.5rem;
  max-width: 400px;
}

.hero-header h2 {
  color: white;/*var(--brown-dark);*/
}

.hero-header p {
  color: white;/*var(--brown-dark);*/
  margin: 10px 0;
  line-height: 25px;
  font-weight: 300;
}

.hero-img {
  width: 500px;
  height: 500px;
  /*object-fit: cover;*/
}

.btn-hero-header-a {
  background-color: var(--brown);
  color: #fff;
  font-weight: bold;
  border-radius: 30px;
  padding: 15px 25px;
  transition: all 0.2s ease-in-out;
}

.btn-hero-header-a:hover {
  background-color: var(--brown-dark);
  color: #fff;
}

.btn-hero-footer {
  background-color: #979797;
  color: #fff;
  font-weight: bold;
  border-radius: 30px;
  padding: 15px 25px;

  transition: all 0.2s ease-in-out;
}

.hero-header__social-media {
  position: absolute;
  right: -30px;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.hero-header__social-media .social-media-icon {
  padding: 5px 0px;
}

.whatsapp {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 40px;
  right: 40px;
  border-radius: 50px;
  text-align: center;
  font-size:30px;
  z-index:1000;
}

.whatsapp-icon {
  margin-top: 10px;
}

@media (max-width: 900px) {
  header {
    text-align: center;
  }
  header .logotipo {
    width: 150px;
  }
  .hero-container-content {
    width: 100%;
    height: 100%;
  }

  .imagem-fundo {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../assets/capa_site_mobile.png");
  }
  .hero-header {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .hero-header__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22vh;
  }
  .hero-header__img {
    order: -1;
  }

  .hero-header__content__title {
    text-align: center;
    margin-top: 30vh;
  }
  .hero-header__content__title p {
    margin-bottom: 30px;
  }

  .hero-header__social-media {
    left: 0;
    right: 80%;
    bottom: 250px;
  }

  .social-media-icon {
    width: 25px;
  }

  .scroll-mouse {
    display: none;
  }

  .hero-img {
    width: 85%;
    object-fit: cover;
  }

  .hero-header h2 {
    color: var(--brown);
  }

  .hero-header p {
    color: var(--brown);
    margin: 10px 0;
    line-height: 25px;
    font-weight: 300;
  }

  .btn-hero-header {
    border-radius: 30px;
    padding: 15px 25px;
  }
}
