.how-it-works__content {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-top: 100px;
}

.how-it-works__content p {
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.how-it-works__content__cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
}

.how-it-works__content__cards .cards {
  /*background-color: var(--gray);*/

  display: flex;
  flex-direction: column;
  align-items: center;  
  width: 45vh;
  height: 250px;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}

.how-it-works {
  position: relative;
}

.how-it-works-flower-right,
.how-it-works-flower-left {
  position: absolute;
  z-index: -9999;
}

.how-it-works-flower-right {
  top: 0;
  right: -10px;
}

.how-it-works__logo {
  margin-bottom: -60px;
  margin-top: 10px;
}

@media (max-width: 900px) {
  .how-it-works-flower-right,
  .how-it-works-flower-left {
    display: none;
  }

  .how-it-works__content__cards {
    flex-direction: column;
    align-items: center;
  }

  .how-it-works__content__cards .cards {
    width: 45vh;
  }

  .how-it-works__logo {
    margin-bottom: -60px;
    margin-top: 10px;
    height: 100px;
  }
}
